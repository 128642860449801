import React from 'react'
import Header from './Header'
import Banner from './Banner'
import Deliver from './Deliver'
import About from './About'
import Prevention from './Prevention'
import Mask from './Mask'
import Basic from './Basic'
import Protection from './Protection'
import Supply from './Supply'
import Feature from './Feature'
import Arrivals from './Arrivals'
import Services from './Services'
import Why from './Why'
import Contact from './Contact'
import Footer from './Footer'
import Footer1 from './Footer1'

export default function HomePage() {
  return (
    <>
     <Header/> 
     <Banner/>
     <Deliver/>
     <About/>
     {/* <Prevention/> */}
     <Mask/>
     <Basic/>
     {/* <Protection/> */}
     <Supply/>
     {/* <Feature/> */}
     <Arrivals/>
     {/* <Services/> */}
     <Why/>
     <Contact/>
     <Footer1/>
    </>
  )
}
