import React from 'react'
import Header from '../Home/Header'
import Banner from '../ABout.jsx/Banner'
import Footer from '../Home/Footer'
import { useParams } from 'react-router-dom'
import Gallary from './Gallary'
import Recommanded from './Recommanded'
import Footer1 from '../Home/Footer1'

export default function Contains() {
     const {name}=useParams()
  return (
    <>
     <Header/>
     <Banner
     title={'Product'} 
     description={name}/>
     <Gallary/>
     <Recommanded/>
     <Footer1/>
    </>
  )
}
