import React from "react";
import Container from "../Home/Container";
import { FaChevronLeft, FaChevronRight, FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation,Autoplay} from 'swiper/modules'
import { motion } from "framer-motion";
export default function Testimonials() {
  return (
    <Container>
      <div className="w-[100%] justify-center  items-center flex px-[5%] py-[5rem] bg-[#2ec4ff] flex-col gap-5 text-white ">
        <h1 className="text-5xl font-bold ">Testimonials</h1>
        <p className="text-lg tracking-[0.3em] font-semibold">
          WHAT OUR CUSTOMERS SAY ABOUT OUR PRODUCTS
        </p>
        <div className=" mt-[3rem] w-[100%] flex relative   ">
        <div style={{zIndex:9}}  className="absolute  w-[100%] px-[-2rem]  flex justify-between items-center ">
              <motion.div
             
                transition={{ duration: 0.3}}
                className="  px-[0.7rem] w-fit bg-[#fdfdfd] py-[0.7rem] rounded-md"
              >
                <FaChevronLeft className="text-[1.5rem] custom-prev text-black" />
              </motion.div>
              <motion.div
                 
                     transition={{ duration: 0.3}}
                     className="  px-[0.7rem] w-fit bg-[#ffffff] py-[0.7rem] rounded-md"
              >
                <FaChevronRight className="text-[1.5rem] custom-next text-black" />
              </motion.div>
            </div>
        <Swiper
         autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
           navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          loop={true}
          modules={[Navigation,Autoplay]}
          className="w-[90%] flex overflow-hidden"
        >
          <SwiperSlide>
          <div className="w-[100%] flex flex-col gap-5 items-center justify-center">
            <h1 className="leading-[1.8rem] tracking-wider text-center text-lg">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
              necessitatibus neque possimus eveniet. Sequi doloribus, blanditiis
              debitis quas facilis beatae tenetur laudantium totam maiores
              ratione quia, nobis reiciendis libero perferendis?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
              necessitatibus neque possimus eveniet. Sequi doloribus, blanditiis
              debitis quas facilis beatae tenetur laudantium totam maiores
              ratione quia, nobis reiciendis libero perferendis?
            </h1>
            <div className="w-[100%] flex text-[1rem] items-center justify-center gap-2">
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            </div>
            <div className="w-[10rem] h-[10rem] rounded-full flex items-center overflow-hidden justify-center">
                <img src={require('../../Assets/gallery-1.webp')} className="h-[100%] object-cover" alt="" />
            </div>
            <h1 className="text-md font-bold text-center w-[100%] flex flex-col justify-center items-center gap-2 ">
                Name
                <div className="w-[5rem] border-t-[2px] border-white" />
                Company Name
            </h1>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="w-[100%] flex flex-col gap-5 items-center justify-center">
            <h1 className="leading-[1.8rem] tracking-wider text-center text-lg">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
              necessitatibus neque possimus eveniet. Sequi doloribus, blanditiis
              debitis quas facilis beatae tenetur laudantium totam maiores
              ratione quia, nobis reiciendis libero perferendis?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
              necessitatibus neque possimus eveniet. Sequi doloribus, blanditiis
              debitis quas facilis beatae tenetur laudantium totam maiores
              ratione quia, nobis reiciendis libero perferendis?
            </h1>
            <div className="w-[100%] flex text-[1rem] items-center justify-center gap-2">
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            </div>
            <div className="w-[10rem] h-[10rem] rounded-full flex items-center overflow-hidden justify-center">
                <img src={require('../../Assets/gallery-1.webp')} className="h-[100%] object-cover" alt="" />
            </div>
            <h1 className="text-md font-bold text-center w-[100%] flex flex-col justify-center items-center gap-2 ">
                Name
                <div className="w-[5rem] border-t-[2px] border-white" />
                Company Name
            </h1>
          </div>
          </SwiperSlide>
          </Swiper>
        </div>
        </div>
   
    </Container>
  );
}
