import React from 'react'
import Container from './Container'
import { FaLinkedinIn, FaPhoneAlt } from 'react-icons/fa'
import { MdOutlineMail } from 'react-icons/md'
import { SlSocialInstagram } from "react-icons/sl";
export default function Footer1() {
  return (
    <Container>

      <div className="w-[100%] px-[3%]  flex flex-col text-white gap-5 items-center justify-center  py-[4rem] bg5">
            <div className='w-[100%] flex items-center justify-between'>
                <div className='w-[30%] flex flex-col gap-5'>
                    <img src={require('../../Assets/logos.jpg')} className='w-[80%]' alt="" />
                    <p className=' text-lg font-semibold tracking-wider leading-[1.7rem]'>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa, officia? Aspernatur 
                    </p>
                    <span className=' text-lg font-semibold tracking-wider leading-[1.7rem]'>Vancouver, BC <br />
                    Canada, V6E3V6</span>
                    <div className=' flex flex-col gap-3'>
                        <div className='flex items-center gap-3'>
                        <FaPhoneAlt className='text-[20px] text-blue-400' />
                        <span className='text-xl font-semobold hover:text-blue-400 transition-all'>
                            <a href="tel:123-456-7890">123-456-7890</a>
                        </span>

                        </div>
                        <div className='flex items-center gap-3'>
                        <MdOutlineMail  className='text-[20px] text-blue-400' />
                        <span className='text-xl font-semobold hover:text-blue-400 transition-all'>
                            <a href="mailto:info@Yourmail.com">info@yourmail.com</a>
                        </span>

                        </div>
                        
                    </div>
                    <div className=' flex items-center gap-5'>
                        <div className='w-[4rem] h-[4rem] rounded-full bg-white flex items-center justify-center'>
                        <FaLinkedinIn className='text-[25px] text-black' />                      
                         </div>
                         <div className='w-[4rem] h-[4rem] rounded-full bg-white flex items-center justify-center'>
                        <SlSocialInstagram  className='text-[25px] text-black' />                      
                         </div>
                    </div>
                    
                </div>
                <div className='w-[40%] bg-white p-3 rounded-xl'>
                    <div className='w-[100%] h-[20rem] flex flex-col overflow-y-scroll gap-3 '>
                        <div className='flex w-[100%] items-center border-b-[1px] border-gray-[300] hover:bg-blue-100 justify-between '>
                        <span className='text-xl font-bold hover:underline transition-all text-black pb-3 '>
                        Posts from
                        <br /> @Rakshaum

                        </span>
                        <div className='text-white bg-black px-5 rounded-full h-fit font-bold text-sm py-1'>
                            Follow
                        </div>
                        </div>
                        <div className='w-[100%] px-5 gap-5  flex flex-col '>
                        <SlSocialInstagram className='text-[3rem] text-black' />
                        <h1 className='text-5xl  font-bold tracking-wider text-black'>
                            Lorem ipsum dolor sit amet 
                        </h1>
                        <p className='text-gray-600'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum expedita, voluptatibus,
                        </p>
                        <div className='w-fit bg-blue-400 px-5 py-3 text-sm font-bold tracking-white rounded-full'>
                                    Join now
                        </div>
                        </div>

                    </div>

                </div>
            </div>   
            <span className='w-[100%] text-left items-start justify-start'>
            <a href="/contact">Contact us </a>/ Privacy Policy / Cookie Policy / © Copyright 2024 / Created by UNITECHNO</span> 
      </div>
    </Container>
  )
}
