import React, { useState } from "react";

export default function AboutCard({ name }) {
  const [first, setFirst] = useState(false);
  return (
    <div
      onMouseEnter={() => setFirst(() => true)}
      onMouseLeave={() => setFirst(() => false)}
      className="w-[100%] flex flex-col gap-3 items-center justify-center"
    >
      <div
        className={`border-[1rem] transition-all duration-500 h-[15rem] ${
          first ? "border-[#31b5e9]" : "border-gray-300"
        } w-[15rem]  rounded-full  overflow-hidden`}
      >
        <img
          src={require("../../Assets/img-1.avif")}
          className="w-[100%] h-[110%]"
          alt="medical devices"
        />
      </div>
      <h1 className="text-3xl font-bold text-[#636566]">{name}</h1>
      <p className="w-[80%] text-center text-gray-500">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos delectus iste impedit
      </p>
    </div>
  );
}
