import React from "react";
import Container from "./Container";
import { FaHandsHelping, FaTruck } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import CountUp from 'react-countup';
export default function Deliver() {
  return (
    <Container>
      <div className="w-[100%] text-gray-700 px-[5%] mt-[-4.3rem]  py-7 flex gap-36 items-center justify-center bg-gray-200">
        <div className=" flex items-center">
         
          <div className="flex flex-col  items-center justify-center gap-1">
            <h1 className="text-5xl font-bold text-[#2ec4ff] pl-3"><CountUp end={500} enableScrollSpy/>+</h1>
            <span className="text-2xl font-bold">Customers</span>
          </div>
        </div>
        <div className=" flex items-center gap-3">
        <div className="flex flex-col  items-center justify-center dap-1">
            <h1 className="text-5xl font-bold text-[#2ec4ff] pl-3"><CountUp end={1000} enableScrollSpy/>+</h1>
            <span className="text-2xl font-bold">Products</span>
          </div>
        </div>
        <div className=" flex items-center ">
        <div className="flex flex-col  items-center justify-center gap-1">
            <h1 className="text-5xl font-bold text-[#2ec4ff] pl-3"><CountUp end={50} enableScrollSpy/>+</h1>
            <span className="text-2xl font-bold">Employees</span>
          </div>
        </div>
      </div>
    </Container>
  );
}
