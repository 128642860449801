import React, { useState } from "react";
import Container from "./Container";
import AboutCard from "../Ui/About";

export default function About() {

  return (
    <Container>
      <div className="w-[100%] flex flex-col gap-3 items-center justify-center">
        <h1 className="text-[#2ec4ff] text-4xl font-bold tracking-wider">
        What we Stand For?
        </h1>
        <span className="text-lg tracking-[0.3em]">
          {" "}
          HIGH PERFORMANCE AND SAFETY
        </span>

        <div className="w-[100%] mt-10 mb-[3rem] grid grid-cols-3 gap-6">
          <AboutCard name='Accuracy'/>
          <AboutCard name='Complete Protection'/>

          <AboutCard name="Prevents Bacteria
"/>

        </div>
      </div>
    </Container>
  );
}
