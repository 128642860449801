import React from "react";
import Container from "./Container";

export default function Mask() {
  return (
    <Container>
      <div className="w-[100%] h-[100%] pb-[4rem] items-center justify-center">
        <div className="w-[100%] flex flex-col gap-3 items-center justify-center">
          <h1 className="text-[#4BC2F2] text-4xl  font-bold tracking-wider">
          OUR PRIME PRODUCT
          </h1>
          <p className="text-[#4C4C4C] text-lg tracking-[0.3em]">
            DURABLE FACE MASKS
          </p>
          <div className="w-[100%] mt-5 grid grid-cols-3 px-[3rem] items-center justify-center gap-5">
          <div className="w-[100%] flex flex-col gap-4">
                <div className="flex flex-col gap-3 w-[100%] items-center justify-center">
                    <img src={require('../../Assets/icon-2.webp')} className="w-[8rem]" alt="" />
                    <h1 className="text-xl font-bold text-center">Lorem ipsum dolo</h1>
                    <p className="text-center w-[80%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos delectus iste impedit

</p>
                </div>
                <div className="flex flex-col gap-3 w-[100%] items-center justify-center">
                    <img src={require('../../Assets/icon-4.webp')} className="w-[8rem]" alt="" />
                    <h1 className="text-xl font-bold text-center">Lorem ipsum dolo
                    </h1>
                    <p className="text-center w-[80%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos delectus iste impedit

</p>
                </div>
          </div>
            <img
              src={require("../../Assets/vecteezy_syringe-medical-drug_24091646.png")}
              alt="Mask 1"
              className="object-cover"
            />
           <div className="w-[100%] flex flex-col gap-4">
                <div className="flex flex-col gap-3 w-[100%] items-center justify-center">
                    <img src={require('../../Assets/icon-3.webp')} className="w-[8rem]" alt="" />
                    <h1 className="text-xl font-bold text-center">Lorem ipsum dolo</h1>
                    <p className="text-center w-[80%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos delectus iste impedit

</p>
                </div>
                <div className="flex flex-col gap-3 w-[100%] items-center justify-center">
                    <img src={require('../../Assets/icon-1.webp')} className="w-[8rem]" alt="" />
                    <h1 className="text-xl font-bold text-center">Lorem ipsum dolo</h1>
                    <p className="text-center w-[80%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos delectus iste impedit

</p>
                </div>
          </div>
           
          </div>
        </div>
      </div>
    </Container>
  );
}
