import React from 'react'
import Header from '../Home/Header'
import Banner from '../ABout.jsx/Banner'
import Footer from '../Home/Footer'
import Map from './Map'
import ContactPage from './ContactPage'
import Footer1 from '../Home/Footer1'


export default function ContactContainer() {
  return (
    <>
     <Header/>
     <Banner title={'Contact us'}
     description={'Contact'}/> 
     <Map/>
     <ContactPage/>
     <Footer1/>
    </>
  )
}
