import React from 'react'
import Header from '../Home/Header'
import Banner from '../ABout.jsx/Banner'
import About from './About'
import Footer from '../Home/Footer'
import Footer1 from '../Home/Footer1'
export default function Career() {
  return (
    <>
     <Header/>
     <Banner title={'Career'}
     description={'Career'}
     /> 
     <About/>
     <Footer1/>
    </>
  )
}
