import React from 'react'
import Header from '../Home/Header'
import Banner from '../ABout.jsx/Banner'
import Footer1 from '../Home/Footer1'
import Teams from './Teams'

export default function Contain() {
  return (
    <>
     <Header/>
     <Banner title={'Our Team'}
     description={'Meet our team'}/> 
     <Teams/>
     <Footer1/>
    </>
  )
}
